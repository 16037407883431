// Header
// ======

.global-header {
  @include z-index('global-header');

  border-radius: 0;
  background: $color-background-alt;
}

.page-header {
  border: none;
  border-radius: 0;
  box-shadow: none;
}

.site-logo {
  max-height: 2.5rem;
}
