// Footer
// ======

.site-contentinfo {
  background-color: $color-background;
}

[data-logo-bit='end'] {
  color: $color-text-brand;
  font-weight: $font-weight-regular;
  text-transform: uppercase;
}

.footer-logo {
  background-size: contain;
  background-repeat: no-repeat;
  min-height: 22px;
  min-width: 100px;
}

.footer-item {
  @media (max-width: $mq-medium) {
    margin-bottom: $spacing-small;
  }
}
